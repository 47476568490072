import React from 'react';
import { Link } from 'gatsby';
import loadable from '@loadable/component';
import { useSearch, useStore } from '../hooks';
import { getToken } from '../services/customer';
import { getAllRules } from '../utils/query';
import SearchIcon from '../images/svg/SearchIcon';
import ActiveskinLogo from '../images/svg/ActiveskinLogo';
import AccountIcon from '../images/svg/AccountIcon';
import WishlistIcon from '../images/svg/WishlistIcon';
import CartIcon from '../images/svg/CartIcon';
import { useMediaQuery } from 'react-responsive';

const HeaderSubNavigation = loadable(() => import('../components/header_sub_navigation'));
const NavMenu = loadable(() => import('../components/mega'));
const SearchBox = loadable(() => import('../components/Search/SearchBox'));

HeaderSubNavigation.preload();
NavMenu.preload();
SearchBox.preload();

const Header = () => {
  const { checkout, setState: setStateStore } = useStore();
  const { searching, setState: setStateSearch } = useSearch();

  const { accessToken, firstName } = getToken();

  const cartCount = (checkout?.lineItems?.map((v) => v.quantity) || []).reduce((a, b) => a + b, 0);

  const isPC = useMediaQuery({ minWidth: 992 });

  const toggleSearch = (value = false) => {
    setStateSearch((s) => ({ ...s, searching: value }));
  };

  const handleCartOpen = () => {
    if (location?.pathname?.indexOf('/cart') === -1) {
      setStateStore((s) => ({ ...s, isCartOpen: true }));
      getAllRules().then((data) => {
        setStateStore((_state) => ({ ..._state, ...data }));
      });
    }
  };

  return (
    <header id="header" className="header">
      {/*header__top*/}
      <div className="header__top container-xl">
        <div className="row align-items-center">
          {/*header__top__btn-toggle-nav*/}
          <div className="header__top__btn-toggle-nav d-lg-none d-flex align-items-center col-3 pr-0">
            <a href="#" onClick={() => event.preventDefault()} id="btn-toggle-nav">
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
            </a>
          </div>
          {/*header__top__btn-toggle-nav*/}

          <div className="header__top__left col-3 d-lg-block d-none">
            <button
              onClick={() => toggleSearch(!searching)}
              className="btn-open-search"
              type="button"
            >
              <SearchIcon white={true} />
            </button>
          </div>

          {/*header__top__center*/}
          <div className="header__top__center col-6">
            <div
              itemScope="itemscope"
              itemType="http://schema.org/Organization"
              className={`text-center m-auto`}
            >
              <div className="header__top__center__logo d-block m-auto">
                <Link
                  to="/"
                  title="Activeskin"
                  className="header__top__center__logo__link"
                  aria-current="page"
                  itemScope="url"
                >
                  <ActiveskinLogo white={isPC} />
                </Link>
              </div>

              <link itemScope="sameAs" href="https://www.instagram.com/activeskin/" />
              <link itemScope="sameAs" href="https://www.facebook.com/activeskin/" />
              <link
                itemScope="sameAs"
                href="https://www.youtube.com/channel/UCknmBq6fpXYs5DExIwGlVYA"
              />
              <link itemScope="sameAs" href="https://www.tiktok.com/@activeskin" />
            </div>
          </div>
          {/*header__top__center*/}

          {/*header__top__right*/}
          <div className="header__top__right col-3 pl-0">
            <div className="header__top__right__user">
              <a
                href={
                  accessToken
                    ? `https://${process.env.GATSBY_SHOPIFY_DOMAIN_CHECKOUT}/account`
                    : '#login'
                }
                className="header__top__right__user__profile"
              >
                {isPC ? <AccountIcon white={true} /> : <AccountIcon />}
              </a>
              {firstName && <span className="profile--name d-lg-block d-none">Hi {firstName}</span>}
            </div>

            <div className="header__top__right__wishlist d-none d-lg-block">
              <div className="header__top__right__wishlist__icon">
                <Link to="/wishlist">
                  {isPC ? <WishlistIcon white={true} /> : <WishlistIcon />}
                </Link>
              </div>
            </div>

            <div className="header__top__right__cart" onClick={handleCartOpen}>
              <div className="header__top__right__cart__icon">
                {isPC ? <CartIcon white={true} /> : <CartIcon />}
                {cartCount > 0 && <span className="quantity">{cartCount}</span>}
              </div>
            </div>
          </div>
          {/*end header__top__right*/}
        </div>
      </div>
      {/*end header__top*/}
      <SearchBox searching={searching} toggleSearch={toggleSearch} />

      {/*header__main*/}
      <NavMenu />
      {/*end header__main*/}

      {/*header__bottom*/}
      <HeaderSubNavigation />
      {/*end header__bottom*/}
    </header>
  );
};

export default Header;
